import '../styles/InfoCard.css';
    
export default function InfoCard() {
    return(
        <div className="info_card hide_for_mobile">
            <ul>
                <li>(239)-766-0003</li>
                <li>riskpeformancellc@gmail.com</li>
                <li>4417 NE 14th Place, Cape Coral FL 33909.</li>
            </ul>
        </div>
    )
}
